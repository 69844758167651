import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ProjectCard from "../components/projectCard"
import WorkCard from "../components/workCard"
import Contact from "../components/contact"
import PDF from "../assets/tomchalancon_CV.pdf"

const WorkCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
`
const ProjectCardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  margin-bottom: 2rem;
`
const SectionTitle = styled.h2`
  font-size: 2rem;
  letter-spacing: 3px;
  font-weight: 700;
  margin-bottom: 0.5rem;

  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`
const AboutParagraph = styled.p`
  line-height: 2.4;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-left: 2rem;
  margin-right: 8rem;

  @media only screen and (max-width: 768px) {
    margin-right: 2rem;
  }
`
const ResumeContainer = styled.div`
  margin: auto;
  box-shadow: inset 0 0 0 0 #eef0f2;

  > :hover,
  > :focus {
    transition: ease-in 0.2s;
    box-shadow: inset 10rem 0 0 0 #eef0f2;
  }
`
const ResumeBtn = styled.button`
  margin: auto;
  text-align: center;
  background-color: white;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  border-radius: 0.75rem;
  border: 3px solid #c4c4c4;
  width: 180px;
  cursor: pointer;

  > :link,
  > :visited {
    text-decoration: none;
    color: #000;
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 2rem;
  }
`

const IndexPage = () => {
  const {
    projects: { edges: projects },
    paragraphs: { edges: paragraphs },
    workExperiences: { edges: workExperiences },
    cv,
  } = useStaticQuery(
    graphql`
      query getAllHomepageContent {
        projects: allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "project" } } }
        ) {
          edges {
            node {
              html
              frontmatter {
                year
                title
                company
                slug
                thumbnail {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }

        paragraphs: allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "paragraph" } } }
        ) {
          edges {
            node {
              rawMarkdownBody
              frontmatter {
                title
              }
            }
          }
        }

        workExperiences: allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "workExperience" } } }
        ) {
          edges {
            node {
              rawMarkdownBody
              frontmatter {
                title
                startingDate
                endingDate
              }
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Seo title="Home" />

      <div id="about"></div>
      <SectionTitle>About</SectionTitle>
      <AboutParagraph>
        {
          paragraphs[
            paragraphs.findIndex(
              ({ node }) => node.frontmatter.title === "About"
            )
          ].node.rawMarkdownBody
        }
      </AboutParagraph>

      <div id="projects">
        <SectionTitle>Projects</SectionTitle>
        <ProjectCardsContainer>
          {projects.map(({ node: { frontmatter } }) => (
            <ProjectCard project={frontmatter} key={frontmatter.title} />
          ))}
        </ProjectCardsContainer>
      </div>

      <div id="work-experience">
        <SectionTitle>Work experience</SectionTitle>
        <WorkCardsContainer>
          {workExperiences.map(({ node }) => (
            <WorkCard work={node} key={node.frontmatter.title} />
          ))}
          <ResumeContainer>
            <ResumeBtn>
              <a href={PDF} target="_blank">
                Download CV
              </a>
            </ResumeBtn>
          </ResumeContainer>
        </WorkCardsContainer>
      </div>

      <div id="contact">
        <SectionTitle>Contact</SectionTitle>
        <Contact />
      </div>
    </Layout>
  )
}

export default IndexPage
