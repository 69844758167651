import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const Nav = styled.div`
  margin-top: 2rem;
`
const Cards = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  padding: 0 1.5rem;
  cursor: pointer;

  box-shadow: inset 0 0 0 0 #eef0f2;

  > :hover,
  > :focus {
    transition: ease-in 0.3s;
    box-shadow: inset 10rem 0 0 0 #eef0f2;
  }
`
const Card = styled.button`
  flex: 1;
  margin: 1rem;
  padding: 0.5rem 2rem;
  border-radius: 0.75rem;
  background-color: white;
  border: 3px solid #c4c4c4;

  > :link,
  > :visited {
    text-decoration: none;
    color: #000;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 1rem;
  }
`

function Contact() {
  const links = [
    {
      label: "Email",
      path: "mailto:tom.chalancon@gmail.com",
    },
    {
      label: "Phone",
      message: "You can call me at +33 6 08 74 11 09",
      alert: true,
    },
    {
      label: "Linkedin",
      path: "https://www.linkedin.com/in/tom-chalancon-5ab873129",
    },
  ]

  return (
    <Nav>
      <Cards>
        {links.map(({ label, path, alert, message }) => (
          <Card
            key={label}
            onClick={() => (alert ? window.alert(message) : null)}
          >
            {!alert ? (
              <a href={path} target="_blank">
                {label}
              </a>
            ) : (
              label
            )}
          </Card>
        ))}
      </Cards>
    </Nav>
  )
}

export default Contact
